'use client';

import { useSession } from 'next-auth/react';
import { useEffect, useState, useRef } from 'react';
import { CheckoutPanel, LineItem, PromoPanel, CartEvents, AfterPayPromo } from '@/components';
import { useLocalStorage } from 'react-use';
import { getCountryData } from '@/components/getCountryData';
import Close from '@/assets/icons/close.svg';
import Chevron from '@/assets/icons/chevron.svg';
import _ from 'lodash';

interface CartDataProps {
    lang?: any | null;
    cart?: any | null;
    setShowCart?: any | null;
    showCart: boolean;
    rules?: any | null;
    setAccount?: any | null
}

const CartData: React.FC<CartDataProps> = ({ lang, cart, setShowCart, showCart, rules, setAccount }) => {
    const { data: session } = useSession() as any;
    const [cartItems, setCartItems] = useState<any[]>([]);
    const [checkout, setCheckout] = useState(false);
    const [updatedLineItems, setUpdatedLineItems] = useState(false);
    const [cartTotal, setCartTotal] = useState(0);
    const [shipping, setShipping] = useState({}) as any;
    const [orderVoucher, setOrderVoucher] = useState(0);
    const [voucherDetails, setVoucherDetails] = useState({}) as any;
    const [savedCart, setSavedCart] = useLocalStorage('savedCart') as any;
    const {currency, priceColumn } = getCountryData(lang);
    const cartRef = useRef<HTMLDivElement>(null);
    const observerRef = useRef<MutationObserver | null>(null);
    const observerTriggered = useRef(false);
    const [orderSummary, setOrderSummary] = useState(false)
    const [ activeEvent, setActiveEvent ]= useState(null) as any
    const [ payment, setPayment ]= useState(null) as any

    const findBestRuleForProduct = (product: any, rules: any) => {
        let bestRule: any = null;
        let highestAmount = 0;
    
        rules.forEach((rule: any) => {
            // console.log(rule)
            rule.rules_json?.products.forEach((rulesProducts: any) => {
                if (rulesProducts.category?.id && product.categoryIdArray?.includes(rulesProducts.category.id)) {
                    // console.log('cat: ', rulesProducts.category?.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.projectname && product.projectName === rulesProducts.projectname) {
                    // console.log('proj: ', rulesProducts.projectname);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.productdata && rulesProducts.productdata.id === product.id) {
                    // console.log('prods: ', rulesProducts.productdata.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts === "all") {
                    // console.log('all: ', rulesProducts.category?.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
            });
        });
    
        return bestRule?.rules_json?.rules;
    };

    const getProducts = async () => {
        // console.log('getproducts');
        setUpdatedLineItems(false)
        let cartIds: number[] = [];
        const savedCartString = localStorage.getItem('savedCart');
        const checkCart = savedCartString ? JSON.parse(savedCartString) : [];
    
        if (session?.user?.uuid) {
            const cartRequest = await fetch(`/api/cart?uuid=${session?.user?.uuid}&lang=${lang}`);
            const cartResult = await cartRequest.json();
            cartIds = _.map(cartResult, 'id');
        }
    
        const localCartIds = _.map(checkCart, 'id');
        cartIds = _.concat(cartIds, localCartIds);
        cartIds = _.uniq(cartIds);
    
        const cartRequest = await fetch(`/api/cart/local`, {
            method: 'POST',
            body: JSON.stringify({ ids: localCartIds, lang: lang }),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const cartResult = await cartRequest.json();
    
        let getTotal = 0;
        const cartLineItems: any[] = [];

        checkCart.forEach((cartItem: any) => {
            cartResult.rows.forEach((product: any) => {
                product.productdata.productOptions.forEach((option: any) => {
                    if (Number(option.id) === Number(cartItem.id)) {
                        // console.log(option.id)
                        let itemPrice = option.priceColumns[priceColumn];
                        let discountPrice = itemPrice;
    
                        const applicablePromotion = product.productdata?.orderType !== "Gift Voucher" ? findBestRuleForProduct(product.productdata, rules) as any : null

                        let discountLabel = null;
                        
                        if (applicablePromotion && option.id !== 4955 && option.id !== 1555 ) {
                            let discounted = applicablePromotion.amount;
                            discounted = applicablePromotion.type === "percentage" ? discounted / 100 : discounted;
                            discountPrice = itemPrice - itemPrice * discounted;
                            discountLabel = applicablePromotion.type === "percentage" ? `${applicablePromotion.amount}% off` : `${currency} ${applicablePromotion.amount} off`;
                        }
    
                        getTotal += cartItem.quantity * discountPrice;
                        
                        cartLineItems.push({
                            options: [option],
                            productdata: product.productdata,
                            product_id: product.product_id,
                            quantity: cartItem.quantity,
                            id: cartItem.id,
                            cart_data: cart,
                            discountPrice,
                            discountLabel,
                            promotion: applicablePromotion && option.id !== 4955 ? true : false, 
                            extra_details: cartItem.details
                        });
                        
                    }
                });
            });
        });

        setCartTotal(getTotal);
        setCartItems(cartLineItems);
    };

    const fetchCart = async () => {
        
        await getProducts();
        setUpdatedLineItems(true)
    };

    useEffect(() => {
        fetchCart();
        const targetNode = cartRef.current;

        if (!targetNode) {
            // console.log("Target node not found.");
            return;
        }

        const config = { attributes: true, attributeFilter: ['class'] };

        const callback = async (mutationsList: MutationRecord[]) => {
            if (observerTriggered.current) return;

            observerTriggered.current = true;
            // console.log("Mutation callback fired");
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    // console.log('Class attribute changed:', mutation.target);
                    await fetchCart();
                }
            }
            observerTriggered.current = false;
        };

        if (observerRef.current) {
            observerRef.current.disconnect();
            // console.log("Previous observer disconnected.");
        }

        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
        observerRef.current = observer;
        // console.log("Observer started.");

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
                // console.log("Observer disconnected.");
            }
        };
    }, [cartRef]);

    return (
        <div ref={cartRef} className={(checkout ? "w-screen" : "w-screen xl:w-[50vw] xl:ml-[50vw]") + " bg-offwhite transform-all duration-500 pt-16 xl:pt-0 relative md:absolute font-norma"} id="cartDetail">
            <div className="absolute z-[1002] right-4 xl:right-[50px] top-4 xl:top-[26px] cursor-pointer hover:opacity-60 bg-offwhite text-[12px]"  
                    onClick={() => {
                        setShowCart(false);
                        setPayment(null);
                        setCheckout(false)
                        document.body.style.overflow="visible"
                        document.querySelector("#cartPanel")?.classList.remove("left-0", "right-0", "md:left-auto");
                        document.querySelector("#cartDetail")?.classList.remove("update-cart");
                        setAccount(false)
                }}>Continue shopping <Close className=" stroke-warmcharcoal inline-block ml-2"/>
            </div>
            <div className="px-5 w-full xl:w-[50vw] md:px-8 xl:px-[54px] transform-all duration-500 bg-offwhite h-screen overflow-hidden overflow-y-scroll md:pb-36">
                <div className="grid grid-cols-2 xl:pt-24">
                    <div>
                        {checkout ? <p className="hidden md:inline-block mb-12 font-norma cursor-pointer hover:opacity-60 text-[12px]" onClick={() => { setShowCart(false); setCheckout(false); }}>Continue Shopping</p> : null}
                        <h2 className="text-[21px] leading-[32px] font-sangbleu font-medium text-warmcharcoal">{checkout ? "Your order" : "Your cart"}</h2>
                    </div>
                    <div className="flex justify-end">
                        <p className="text-[14px] leading-[32px] font-norma font-normal text-warmcharcoal">{checkout ? "" : `(${cartItems.length})`}</p>
                    </div>
                </div>
                <div className="relative border-b-[1px] border-b-warmcharcoal pb-2 mb-8 md:mb-8">
                    <p className="xl:hidden flex justify-between cursor-pointer" onClick={()=>setOrderSummary(!orderSummary)}><span className="inline-block font-medium text-[14px] mt-2">Show order summary</span><Chevron className={"stroke-warmcharcoal inline-block mt-4 " +(orderSummary=== true ? "" : "rotate-180")}/></p>
                    <div className={(orderSummary === false && !checkout ? "block" : orderSummary === false && checkout ? "hidden xl:block" : "block") + " md:mt-4 xl:pt-[100px]"}>
                        {updatedLineItems === true && cartItems.map((item, index) => (
                            <LineItem cartItems={cartItems} setCartItems={setCartItems} item={item} cartTotal={cartTotal} setCartTotal={setCartTotal} key={index} index={index} lang={lang} uuid={session?.user?.uuid} />
                        ))}
                    </div>
                </div>
                {checkout ? (
                    <>
                        <div className=" pb-6 font-norma font-medium text-[14px] grid grid-cols-12 gap-5 ">
                            <div className="col-span-6">Subtotal</div>
                            <div className="col-span-6 flex justify-end">{currency} {cartTotal?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            <div className="col-span-6 text-warmgrey">Shipping</div>
                            <div className="col-span-6 flex justify-end text-warmgrey">{currency} {(shipping.price !== null && shipping.price > 0 ? shipping.price : 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        </div>
                        <PromoPanel setOrderVoucher={setOrderVoucher} cartTotal={cartTotal} setCartTotal={setCartTotal} lang={lang} cartItems={cartItems} setCartItems={setCartItems} setVoucherDetails={setVoucherDetails} showCart={showCart} checkout={checkout}/>
                        
                        {cartTotal > 0 ?
                        <div className="mx-auto text-center pb-4">
                            <AfterPayPromo amount={cartTotal.toFixed(2)} currency={currency.replace("$", "")} lang={lang} cart={true}/>
                        </div>
                        : <></>}
                        <div className="border-t-[1px] border-t-warmcharcoal py-6 font-norma font-medium text-[16px] grid grid-cols-12 gap-5">
                            <div className="col-span-6">Total due</div>
                            <div className="col-span-6 flex justify-end">{currency} {(shipping.price > 0 ? cartTotal + shipping.price : cartTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!d))/g, ",")}</div>
                        </div>
                        <ul className="hidden xl:block m-0 p-0 list-none mt-20">
                            <li className="list-none text-[14px] font-norma font-medium cursor-pointer md:inline-block hover:opacity:60 mr-3 mb-2"><a href={`${lang}shipping-returns`}>Shipping + returns</a></li>
                            <li className="list-none text-[14px] font-norma font-medium cursor-pointer md:inline-block hover:opacity-60 mr-3"><a href={`${lang}/terms-conditions`}>Terms + conditions</a></li>
                        </ul>
                        
                    </>
                ) : (
                    <>
                        
                    <button
                        type="button"
                        onClick={() => {
                            cartTotal > 0 ? setCheckout(!checkout) : setShowCart(!showCart);
                            cartTotal > 0 ? setActiveEvent("begin_checkout") : setActiveEvent(null) 

                        }}
                        className="text-offwhite text-[14px] font-norma font-medium bg-warmcharcoal w-full h-[42px] cursor-pointer relative hover:opacity-60 "
                    >
                        {cartTotal > 0 ? `Checkout - ${currency} ${cartTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : 'Nothing in your cart - continue shopping'}
                    </button>
                    {cartTotal > 0 ?
                    <div className="border-[1px] border-warmcharcoal w-full h-[40px] flex items-center justify-center mb-[200px] mt-6 text-[14px]"  
                            onClick={() => {
                                setShowCart(false);
                                setPayment(null);
                                setCheckout(false)
                                document.body.style.overflow="visible"
                                document.querySelector("#cartPanel")?.classList.remove("left-0", "right-0", "md:left-auto");
                                document.querySelector("#cartDetail")?.classList.remove("update-cart");
                        }}>Continue shopping</div>
                    :<></>}
                    </>
                )}
                <div className="xl:hidden">
                    {checkout ? <CheckoutPanel payment={payment} setPayment={setPayment} shipping={shipping} setShipping={setShipping} cartTotal={cartTotal} cartItems={cartItems} orderVoucher={orderVoucher} voucherDetails={voucherDetails} lang={lang} setCheckout={setCheckout} checkout={checkout} setActiveEvent={setActiveEvent}/> : null}
                </div>
                
            </div>
            <div className={(checkout ? "hidden xl:block w-full xl:w-[50vw] pr-5 xl:pr-[54px] z-[100] opacity-100 right-0 " : "opacity-0 w-0 ") + " xl:absolute top-0 pt-12 xl:pt-24 transform-all duration-500 bg-offwhite h-screen"}>
                <Close
                    onClick={() => { setShowCart(!showCart); document.body.style.overflow="visible"; setCheckout(false)}}
                    className="absolute right-4 xl:right-[50px] top-4 xl:top-[26px] stroke-warmcharcoal cursor-pointer hover:opacity-60"
                />
                {checkout ? <CheckoutPanel fetchCart={fetchCart} payment={payment} setPayment={setPayment} shipping={shipping} setShipping={setShipping} cartTotal={cartTotal} cartItems={cartItems} orderVoucher={orderVoucher} voucherDetails={voucherDetails} lang={lang} setCheckout={setCheckout} checkout={checkout} setActiveEvent={setActiveEvent}/> : null}
            </div>
            <CartEvents lang={lang} cartItems={savedCart} addToCart={false} rules={rules} activeEvent={activeEvent}/>
        </div>
    );
};

export default CartData;
