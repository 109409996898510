import type { AuthOptions } from "next-auth"
import CredentialsProvider from "next-auth/providers/credentials"
import { sql } from '@vercel/postgres'
import { compare } from 'bcryptjs';

export const authOptions: AuthOptions = {
    session: {
        strategy: "jwt",
        maxAge: 12 * 30 * 24 * 60 * 60, // 12 months
    },
    providers: [
        CredentialsProvider({
            credentials: {
                email: {},
                password: {}
            },
            async authorize(credentials) {
                const { rows } = await sql`SELECT 
                                                customers.id,
                                                customers.uuid, 
                                                customers.email,
                                                customers.verificationtoken,
                                                customers.hashed_password,
                                                customers.verified,
                                                customers.first_name,
                                                customers.last_name,
                                                customers.cin7_id,
                                                customers.wishlist,
                                                customers.admin,
                                                customers.customer,
                                                CASE 
                                                    WHEN customers.verificationtoken IS NULL THEN 'Please check your login details'
                                                    WHEN customers.verificationtoken != '' AND customers.verified = 'false' THEN 'Please verify your account - check your email - or reset your password'
                                                    WHEN customers.verificationtoken != '' AND customers.verified = 'true' THEN 'true'
                                                    ELSE 'Please check your login details'
                                                END AS status,
                                                CASE 
                                                    WHEN COUNT(orders.uuid) > 0 THEN true
                                                    ELSE false
                                                END AS ordered
                                            FROM 
                                                customers
                                            LEFT JOIN 
                                                orders ON customers.uuid::varchar = orders.uuid
                                            WHERE 
                                                customers.email =  ${credentials?.email as string}
                                            GROUP BY 
                                                customers.id,
                                                customers.uuid, 
                                                customers.email,
                                                customers.verificationtoken,
                                                customers.hashed_password,
                                                customers.verified,
                                                customers.first_name,
                                                customers.last_name,
                                                customers.cin7_id,
                                                customers.wishlist,
                                                customers.admin,
                                                customers.customer`;

                const user = rows[0];

                // console.log(user)
                

                if (user?.status === 'true' && await compare(credentials!.password as string, user.hashed_password)) {
                    // console.log('User authenticated successfully');
                    return { id: user.id, uuid: user.uuid, first_name: user.first_name, last_name: user.last_name, email: user.email, cin7_id: user.cin7_id, wishlist: user.wishlist, admin: user.admin, ordered: user.ordered, customer: user.customer};
                } else {
                // No user found, so this is their first attempt to login=
                throw new Error(user?.status ? user.status : 'Please check your login details')
                }
            }
        })
    ],
    pages: {
        signIn: `/?auth=login`,
        signOut: `/?auth=logout`,
        error: `/`, // Error code passed in query string as ?error=
    },
    callbacks: {
        session: ({ session, token }) => {
        return {
            ...session,
            user: {
            ...session.user,
            id: token.id,
            uuid: token.uuid,
            wishlist: token.wishlist,
            first_name: token.first_name, 
            last_name: token.last_name, 
            email: token.email,
            admin: token.admin,
            cin7_id: token.cin7_id,
            ordered: token.ordered,
            customer: token.customer
            },
        };
        },
        jwt: ({ token, user }) => {
        if (user) {
            const u = user as unknown as any;
            token.redirectUrl = `/`;
            return {
            ...token,
            id: u.id,
            uuid: u.uuid,
            wishlist: u.wishlist,
            first_name: u.first_name, 
            last_name: u.last_name, 
            email: u.email,
            admin: u.admin,
            cin7_id: u.cin7_id,
            ordered: u.ordered,
            customer: u.customer
            };
        }
        return token;
        }
    },
};

