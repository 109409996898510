'use client'
import { useRef, useEffect, useState } from 'react'
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useSession, signOut } from "next-auth/react"
import NextLink from 'next/link'
import { useLocalStorage } from 'react-use'
import GeorgeStreetLinenLogo from '@/assets/icons/gsl-horizontal-logo.svg'
import GeorgeStreetLinenLogoStacked from '@/assets/icons/george-street-linen-logo.svg'
import { useWindowScroll } from 'react-use'
import { MegaMenuButton, AlertPanel} from '@/components'
import Chevron from '@/assets/icons/chevron.svg'
import SearchIcon from '@/assets/icons/search.svg'
import Cart from '@/assets/icons/cart-empty.svg'
import CartFull from '@/assets/icons/cart-full.svg'
import Account from '@/assets/icons/account.svg'
import Moment from 'moment'
import _ from 'lodash'

interface HeaderProps {
    isPanelVisible?: any | null
    setIsPanelVisible?: any | null
    alertHeight?: any | null
    setAlertHeight?: any | null
    chrome?: any | null
    menus?: any | null
    theme?: any | null
    lang?: any | null
    uid?:any | null
    menuOpen?:any | null
    setMenuOpen?:any | null
    megaOpen?: any | null
    setAccount?: any | null
    account?: any | null
    setMegaOpen?: any | null
    countryMenu?: any | null
    setCountryMenu?: any | null
    showCart?: any | null
    setShowCart?: any | null
    setShowSearch?: any | null
    showSearch?: any | null
    alerts?: any | null
}

const Header:React.FC<HeaderProps> = ({isPanelVisible, setIsPanelVisible, alertHeight, setAlertHeight, chrome, theme, menus, lang, menuOpen, uid, setMenuOpen, megaOpen, setMegaOpen, account, setAccount, countryMenu, setCountryMenu, showCart, setShowCart, setShowSearch, showSearch, alerts}) => {

    const router = useRouter();
    const pathname = usePathname();

    const { data: session, status } = useSession()

    const user = session?.user as any;

    const bgRef = useRef(null) as any;
    const shadowRef = useRef(null) as any;
    const {x, y} = useWindowScroll();

    const showCartMenu = ()=>{
        //shows the cart
        setShowCart(!showCart); 
        setAccount(false);
        document.body.style.overflow="hidden"
    }

    useEffect(()=>{
        if(document.getElementById("mininav")) {
            const nav = document.getElementById("mininav") as any
            if( y - nav.offsetTop > -20 ) {
                nav.classList.remove("pb-12", "pt-5", "py-5")
                nav.classList.add("hidden", "pb-4", "md:block","fixed", "top-[55px]", "left-[0px]", "right-[0px]", "duration-500", "z-[30]", "bg-offwhite")
            } 
            if(y < 30) {
                nav.classList.add("pb-12", "pt-5")
                nav.classList.remove("hidden", "pb-4", "md:block", "fixed", "top-[55px]", "left-[0px]", "right-[0px]", "duration-500", "z-[30]", "bg-offwhite")
            }
        }
    },[y])

    const [ cartTotal, setCartTotal] = useState(null) as any
    const [ alertPanel, setAlertPanel ] = useState(true)
    const [ local, setLocal] = useLocalStorage('noalert') as any
    const [ isLoaded, setIsLoaded ] = useState(false)
    const [ testTheme, setTestTheme] = useState(theme ? theme : false);

    useEffect(() => {
        if (menuOpen !== true) { // Only change theme based on scroll when menuOpen is false
            if (y > 100) {
                setTestTheme(false);
                bgRef.current?.classList.add("bg-offwhite", "h-[70px]");
                shadowRef?.current?.classList.add("hidden");
            } else {
                setTestTheme(theme);
                bgRef.current?.classList.remove("bg-offwhite", "h-[70px]");
                shadowRef?.current?.classList.remove("hidden");
            }
        } else {
            setTestTheme(false);
            bgRef.current?.classList.add("bg-offwhite", "h-[70px]");
            shadowRef?.current?.classList.add("hidden");
        }
    }, [y, menuOpen]); // Only listen to y and menuOpen to trigger changes
    

    const setSwitchCountry = (country: string, event:any) => {
        event.stopPropagation();
        const savedCartString = localStorage.setItem('savedCart',[] as any);
        const newParam = country;
        const newPath = pathname.replace(`/${lang}`, `/${newParam}`);
        window.open(newPath, "_self");
    }

    const doSignOut = ()=> {
        signOut();
        router.push(`/${lang}/?auth=logged-out`);
    }

    const removeAlertPanel = () => {
        setAlertPanel(false)
        setIsPanelVisible(false)
        setLocal({time: Moment().add(2, 'days').format("YYYY-MM-DD")})
    }
    
    const searchParams = useSearchParams();
    let authState = searchParams.get("auth");  
    let token = searchParams.get("token");
    let vtoken = searchParams.get("vtoken");

    useEffect(()=>{
        async function getLocalisedCart() {
            let cartIds: number[] = [];
            const savedCartString = localStorage.getItem('savedCart');
            const checkCart = savedCartString ? JSON.parse(savedCartString) : [];
        
            if (user?.uuid) {
                const uuidCartRequest = await fetch(`/api/cart?uuid=${user?.uuid}&lang=${lang}`);
                const uuidCartResult = await uuidCartRequest.json();
                cartIds = _.map(uuidCartResult, 'id');
            } 
        
            const localCartIds = _.map(checkCart, 'id');
            cartIds = _.concat(cartIds, localCartIds);
            cartIds = _.uniq(cartIds);
        
            const cartRequest = await fetch(`/api/cart/local`, {
                method: 'POST',
                body: JSON.stringify({ ids: localCartIds, lang: lang }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const cartResult = await cartRequest.json();

            setCartTotal(cartResult?.rows?.length);
            return null
        }

        getLocalisedCart()

        switch(authState) {
            case 'reset' :
                setAccount(true)
            break;
            case 'login' :
                setAccount(true)
            break;
            case 'logged-out' :
                setAccount(true)
            break;
            default:
        }

        setIsLoaded(true)
    }, [showCart])


    let showPanel = true as any

    const accountShow =() => {
        document.body.style.overflow="hidden";
        setAccount(true)
    }

    return (
        <div className={(menuOpen == true ? "bg-offwhite" : "" )+ " fixed z-[200] top-0 w-screen"}>
        {showPanel === true && alertPanel === true ? 
            <AlertPanel isPanelVisible={isPanelVisible} setIsPanelVisible={setIsPanelVisible} menus={menus} setAlertHeight={setAlertHeight} removeAlertPanel={removeAlertPanel} lang={lang} uid={uid} setAlertPanel={setAlertPanel} local={local}/>
        : <></>}
        <MegaMenuButton alertPanel={alertPanel} alertHeight={alertHeight} chrome={chrome} theme={testTheme} menuOpen={menuOpen} setMenuOpen={setMenuOpen} megaOpen={megaOpen} setMegaOpen={setMegaOpen} local={local}/>
        {testTheme===true && menuOpen !== true?
            <div ref={shadowRef} className={(showPanel === true  && alertPanel=== true && (!local?.time || local?.time < Moment().format("YYYY-MM-DD"))  ? "top-0":"top-0") +" absolute left-0 w-screen bg-gradient-to-b from-warmcharcoal/80 via-warmcharcoal/50 to-transparent h-36 -z-10 opacity-70"}></div>
        :<></>}   
        <div className={(showPanel === true  && alertPanel === true && isLoaded=== true ? "top-[47px]":"top-0") +" grid grid-cols-12 w-screen md:w-full p-5 transition-all duration-300 z-20 gap-0"} ref={bgRef}>   
            <div className="col-span-2 md:col-span-3 lg:col-span-6 order-3 md:order-1 flex justify-end md:justify-normal">
                <NextLink href={"/"+lang} title="George Street Linen - Home" onClick={()=>document.body.style.overflow = "visible"}>
                    <GeorgeStreetLinenLogo className={(testTheme === true  ? "fill-offwhite " : "fill-warmcharcoal ") + " w-[135px] h-[16.88px] md:w-[171px] md:h-[21.38px] lg:w-[249px] lg:h-[31.13px] relative lg:left-[34px] md:mb-4 top-1 md:top-[2px] lg:top-0 hidden md:block"} alt="George Street Linen - Home"/>
                    <GeorgeStreetLinenLogoStacked className={(testTheme === true  ? "fill-offwhite " : "fill-warmcharcoal ") + " w-[44px] h-[44px] -top-2 relative block md:hidden"} alt="George Street Linen - Home"/>
                </NextLink>
            </div>
            <div className="md:hidden col-span-8 order-2 md:order-2">
                <ul className="">
                    <li className={(testTheme === true  ? "text-offwhite " : "text-warmcharcoal ") + "inline-block text-[12px] md:text-[14px] font-norma md:ml-[40px] relative cursor-pointer "} onClick={()=>setCountryMenu(!countryMenu)}>
                    <span className="uppercase ">{lang}</span> {lang !== "global" ? "site " : " "}<Chevron className={(testTheme === true  ? "stroke-offwhite" : "stroke-warmcharcoal ") +" inline-block ml-1 rotate-180"}/>
                        {countryMenu === true ?
                            <ul className="block absolute -left-2 top-[30px] w-[94px] m-0 bg-offwhite shadow-lg">
                                <li className={(lang ==="nz" ? "font-medium bg-warmgrey/10" :"") + " text-warmcharcoal block text-[14px] md:text-[14px] font-norma  border-b-[1px] border-b-warmgrey/30 p-2 cursor-pointer"} onClick={(e)=>setSwitchCountry("nz", e)}>NZ ($NZD)</li>
                                <li className={(lang ==="au" ? "font-medium bg-warmgrey/10" :"") + " text-warmcharcoal block text-[14px] md:text-[14px] font-norma border-b-[1px] border-b-warmgrey/30 p-2 cursor-pointer"}  onClick={(e)=>setSwitchCountry("au", e)}>AU ($AUD)</li>
                                <li className={(lang ==="global" ? "font-medium bg-warmgrey/10" :"") + " text-warmcharcoal block text-[12px] md:text-[14px] font-norma border-b-[1px] border-b-warmgrey/30 p-2 cursor-pointer"}  onClick={(e)=>setSwitchCountry("global", e)}>US ($USD)</li>
                            </ul>
                        :<></>}
                    </li>
                    {menus.data.header_navigation?.map((item: any, index: number)=>{
                        if(item.link_text === "Login" && user?.uuid) {
                            item.link_text = "Account"
                        }
                        return (
                            <li onClick={()=>{ item.link_text === "Login" || item.link_text === "Account" ?  accountShow() : item.link_text === "Cart" ? showCartMenu() : null}} 
                                className={(testTheme === true  ? "text-offwhite " : "text-warmcharcoal ") + " inline-block text-[14px] font-norma  mr-[20px] cursor-pointer relative z-20 "} key={index}>
                                    {item.link_text === "Login" || item.link_text === "Account" ? 
                                        <span className="relative">{item.link_text === "Account" ? <Account className={(testTheme === true  ? "stroke-offwhite " : "stroke-warmcharcoal ") + " top-[2px] relative"}/> : item.link_text}</span>
                                    :<></>}
                                    {item.link_text === "Cart" ?
                                    <span className="relative top-[3px]">{cartTotal > 0 ? <span className="relative inline-block"><CartFull className={testTheme === true  ? "stroke-offwhite fill-offwhite " : "stroke-warmcharcoal fill-warmcharcoal"}/><span className={(testTheme === true  ? "text-warmcharcoal " : "text-offwhite ") + " absolute  text-[8.5px] -top-[4.6px] left-[5px] w-[13px] text-center font-medium"}>{cartTotal}</span></span> : <Cart className={testTheme === true  ? "stroke-offwhite " : "stroke-warmcharcoal "}/>}</span>
                                    :<></>}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="col-span-2 md:col-span-9 lg:col-span-6 flex md:justify-end md:pr-[140px] md:w-full lg:mt-[2px] order-1 md:order-3">
                <ul className="hidden md:block relative z-[100] ">
                    <li className={(testTheme === true  ? "text-offwhite " : "text-warmcharcoal ") + " inline-block text-[14px] font-norma ml-[40px] relative cursor-pointer"} onClick={()=>setCountryMenu(!countryMenu)}>
                    <span className="uppercase">{lang} </span>{lang !== "global" ? "Region " : " "}<Chevron className={(testTheme === true  ? "stroke-offwhite" : "stroke-warmcharcoal ") +" inline-block ml-1 rotate-180"}/> 
                        {countryMenu === true ?
                            <ul className="block absolute -left-2 top-[30px] w-[94px] m-0 bg-offwhite shadow-lg">
                                <li className={(lang ==="nz" ? "font-medium bg-warmgrey/10" :"") + " text-warmcharcoal block text-[14px] font-norma  border-b-[1px] border-b-warmgrey/30 p-2 cursor-pointer"} onClick={(e)=>setSwitchCountry("nz", e)}>NZ ($NZD)</li>
                                <li className={(lang ==="au" ? "font-medium bg-warmgrey/10" :"") + " text-warmcharcoal block text-[14px] font-norma border-b-[1px] border-b-warmgrey/30 p-2 cursor-pointer"}  onClick={(e)=>setSwitchCountry("au", e)}>AU ($AUD)</li>
                                <li className={(lang ==="global" ? "font-medium bg-warmgrey/10" :"") + " text-warmcharcoal block text-[14px] font-norma border-b-[1px] border-b-warmgrey/30 p-2 cursor-pointer"}  onClick={(e)=>setSwitchCountry("global", e)}>US ($USD)</li>
                            </ul>
                        :<></>}
                    </li>
                    {menus.data.header_navigation?.map((item: any, index: number)=>{
                        if(item.link_text === "Login" && user?.uuid) {
                            item.link_text = "Account"
                        }
                        return (
                            <li onClick={()=>{ item.link_text === "Login" || item.link_text === "Account" ?  accountShow() : item.link_text === "Cart" ? showCartMenu() : null}} 
                                className={(testTheme === true  ? "text-offwhite " : "text-warmcharcoal ") + " inline-block text-[14px] font-norma ml-[40px] cursor-pointer relative z-20"} key={index}>
                                    {item.link_text === "Login" || item.link_text === "Account" ? 
                                        <span className="relative">{item.link_text === "Account" ? <Account className={(testTheme === true  ? "stroke-offwhite " : "stroke-warmcharcoal ") + " top-[2px] relative"}/> : item.link_text}</span>
                                    :<></>}
                                    
                                    {/* {item.link_text === "Shop all" ?
                                    <a href={item.link?.uid ? "/"+lang+"/"+item.link.uid : item.link?.url}>{item.link_text}</a>
                                    : <></>} */}
                                    {item.link_text === "Cart" ?
                                    <span className="relative top-[3px]">{cartTotal > 0 ? <span className="relative inline-block"><CartFull className={testTheme === true  ? "stroke-offwhite fill-offwhite " : "stroke-warmcharcoal fill-warmcharcoal"}/><span className={(testTheme === true  ? "text-warmcharcoal " : "text-offwhite ") + " absolute  text-[8.5px] -top-[4.6px] left-[5px] w-[13px] text-center font-medium"}>{cartTotal}</span></span> : <Cart className={testTheme === true  ? "stroke-offwhite " : "stroke-warmcharcoal "}/>}</span>
                                    :<></>}
                            </li>
                        )
                    })}
                    <li className="inline-block ml-[40px]"><SearchIcon className={(testTheme === true  ? "stroke-offwhite " : "stroke-warmcharcoal ") + " w-[15px] h-[15px] inline-block cursor-pointer"} onClick={()=>{setShowSearch(true); document.body.style.overflow="hidden"}}/></li>
                </ul>
            </div>
        </div>
    </div>
    )
}

export default Header
