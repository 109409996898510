'use client'
import { useState, useRef, useEffect, Fragment } from 'react' 
import { getCountryData } from '@/components/getCountryData'
import { useForm, Controller } from 'react-hook-form'; 
import { VoucherLineItem } from '@/components'
import Tick from '@/assets/icons/tick.svg'

import _ from 'lodash'
import Moment from 'moment'

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface VoucherAdminPanelProps {
    vouchers?: any | null
    values?: any | null
}

    const VoucherAdminPanel:React.FC<VoucherAdminPanelProps>  = ({ vouchers, values }) => {

        const { register, handleSubmit, watch, reset, formState: { errors }, setValue } = useForm();
        const { currency, priceColumn} = getCountryData("nz")

        const [ voucherData, setVoucherData ] = useState(vouchers?.length > 0 ? vouchers : null) as any
        const [ updating, setUpdating] = useState(false)
        const [ voucher, setVoucher ] = useState(null) as any
        const [ archive, setArchive] = useState(false) as any
        const [ selectedProductIds, setSelectedProductIds ] = useState<{ id: number, quantity: number }[]>([]) as any;
        const [ valError, setValError] = useState(null) as any
        const [ valueVoucher, setValueVoucher ] = useState(null) as any
        const [ product,  setProduct ] =useState(null) as any 

        return (
            
            <div className="px-6 md:px-[50px] py-24 font-norma">
                <h1 className="font-sangbleu font-light text-[24px] mb-12">eGift Voucher Management</h1>
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-8">
                        <div className="flex justify-between items center">
                            <h2 className="font-norma font-medium text-[21px] leading-[18px] mt-6 mb-12">
                                <span className="inline-flex text-offwhite bg-warmcharcoal rounded-full text-[16px] font-medium h-[28px] w-[28px] items-center justify-center mr-3 relative -top-[2px]">A</span>
                                View eGift vouchers 
                            </h2>
                            <div className="text-[14px] font-medium block mt-6 cursor-pointer" onClick={()=>setArchive(!archive)}><span className={(archive ? "bg-warmcharcoal" : "")+" border-[1px] border-warmcharcoal h-[20px] w-[20px] mr-2 inline-block rounded-full relative top-1"}></span> <span>Include $0 balances</span></div>
                        </div>
                        {voucherData?.map((item: any, index: any)=>{
                            if(item.value > 0 || archive === true) {
                            return (
                                <VoucherLineItem voucher={item} key={index} archive={archive} />
                            )
                        } return <></>
                        })}
                    </div>
                    
                    <div className="col-span-4 border-l-[1px] border-warmcharcoal px-12 py-6">
                        <h2 className="font-norma font-medium text-[21px] leading-[18px] mb-12">
                            <span className="inline-flex text-offwhite bg-warmcharcoal rounded-full text-[16px] font-bold h-[28px] w-[28px] items-center justify-center mr-3 relative -top-[2px]">B</span>
                            Send new eGift voucher
                        </h2>
                        <form onSubmit={handleSubmit(async (data: any) => {

                            const order = { 
                                    reference: "GSL-INTERNAL-"+Moment().format("YYYMMDD-HHMMSS"),
                                    currencyCode: "NZD",
                                    lineItems: [
                                                    {
                                                        qty: 1,
                                                        url: "https://www.georgestreetlinen.com/online-gift-voucher/digital-card",
                                                        code: product.code,
                                                        name: "Online Gift Voucher",
                                                        details: {
                                                            name: data?.name,
                                                            email: data?.email,
                                                            address: null,
                                                            message: data?.message,
                                                            voucherType: "Digital Card"
                                                        },
                                                        option1: "Digital Card",
                                                        option2: product.option2,
                                                        option3: "",
                                                        discount: 0,
                                                        unitPrice: product.option2,
                                                        accountCode: "",
                                                        createdDate: new Date().toISOString(),
                                                        lineComments: `${data?.email} , To: ${data?.name}, Message: ${data?.message}`,
                                                        productOptionId: product.id
                                                    }
                                    ]
                                }

                            setUpdating(true);
                            const sendDigitalVouchers = await fetch(`/api/orders/voucher-send`, {method: "POST", body: JSON.stringify(order)});
                            const digitalVouchersSent = await sendDigitalVouchers.json();
                            
                            setUpdating(false);

                            window.location.reload()
                        })}>
                            <h5 className="font-medium mb-4">eGift Voucher Value</h5>
                            <div className="grid grid-cols-12 gap-4 mb-12">
                            {values?.map((item: any, index: number)=>{
                                return (
                                    <Fragment key={index}>
                                        {item.productdata?.productOptions?.map((value: any, vIndex: number)=>{
                                            if(value?.option1 === "Digital Card") {
                                            return (
                                                <div className="col-span-6" key={vIndex}>
                                                    <button
                                                        type="button"
                                                        className={
                                                            (voucher === value.id
                                                                ? "text-offwhite font-medium bg-warmcharcoal"
                                                                : "text-warmcharcoal bg-white") +
                                                            " h-[42px] text-[16px] w-full text-center"
                                                        }
                                                        onClick={() => {
                                                                setSelectedProductIds([...selectedProductIds, { id: value.id, quantity: 1 }]);
                                                                setVoucher(value.id); // Store only the current voucher
                                                                setValueVoucher(value.priceColumns[priceColumn])
                                                                setValError(null)
                                                                setProduct(value)
                                                        }}
                                                    >
                                                        {currency} {value.priceColumns[priceColumn]}
                                                    </button>
                                                </div>
                                            )
                                        }
                                        })
                                    }
                                    </Fragment>)}
                            )}
                            </div>
                            <h5 className="font-medium mb-4">Recipient details</h5>
                            <input type="text" placeholder="Name" className="w-full mb-4 p-2 text-[16px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal" {...register('name')}/>
                            <input type="email" placeholder="Email" className="w-full mb-4 p-2 text-[16px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal" {...register('email', {required: true})}/>
                            <textarea placeholder="Message (250 characters)" rows={4} className="w-full mb-4 p-2 text-[16px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal" {...register('message')}/>
                                {!updating ?
                                <input type="submit" defaultValue="Send Voucher" className="w-full py-3 px-4 mb-4 bg-warmcharcoal text-offwhite font-norma font-medium cursor-pointer hover:opacity-60"/>
                                : <div className="w-full py-3 px-4 mb-4 bg-warmcharcoal/50 text-offwhite font-norma font-medium text-center">Sending</div>}
                        </form>
                    </div>
                </div>
                
                            
            </div>
    )
}

export default VoucherAdminPanel
